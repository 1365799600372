<template>
  <VContent>
    <template slot="right">
      <h6>
        {{ title }} >
        <span class="text-muted">{{ type ? "الوارد" : "الصادر" }}</span>
      </h6>
    </template>
    <template slot="left">
      <b-button size="sm" variant="success" class="ml-3" @click="update()"
        >تحديث</b-button
      >
    </template>
    <template slot="content">
      <b-tabs content-class="mt-3" v-model="type">
        <b-tab title="الصادر" active>
          <VTextEditor
            v-for="(item, index) in information"
            :label="item.text"
            :key="index"
            class="mb-5"
            v-model="form.ingoing[item.key]"
          ></VTextEditor>
        </b-tab>
        <b-tab title="الوارد">
          <VTextEditor
            v-for="(item, index) in information"
            :label="item.text"
            :key="index"
            v-model="form.outgoing[item.key]"
          ></VTextEditor>
        </b-tab>
      </b-tabs>
    </template>
  </VContent>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  data() {
    return {
      id: null,
      type: 0,
      title: null,
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.external.form;
      },
      set(value) {
        this.$store.commit("banks/external/SET_FUNDING", value);
      },
    },
    information() {
      return [
        { key: "about", text: `حول ${this.title}` },
        { key: "procedures", text: "الإجراءات المطلوبة" },
        { key: "documents", text: "الوثائق" },
        { key: "legal_framework", text: "الأطار القانوني" },
      ];
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.id = this.$route.meta.id;
      this.title = this.$route.name;
      this.$store.dispatch("banks/external/get", this.id);
    },
    update() {
      this.$store
        .dispatch("banks/external/update", this.id)
        .then((response) => {
          successAlert();
          this.$store.commit("banks/external/SET_FUNDING", response.payload);
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
    },
  },
};
</script>
<style></style>
